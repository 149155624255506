import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../../../components/layout";
import ProjectHeader from "../../../components/layouts/ProjectHeader";
import { TechnologySection, TechEntry } from "../../../sections/Project-Page/TechnologySection";
import { BreakdownSection, BreakdownGrid } from "../../../sections/Project-Page/BreakdownSection";

const ProjectTurnip = () => {

    const { mobileImage, desktopImage } = useStaticQuery(
        graphql`
            query {
                mobileImage: file(relativePath: { eq: "colorcomb.png" }) {
                    childImageSharp {
                    fluid(maxWidth: 490, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    }
                }
                desktopImage: file(relativePath: { eq: "colorcomb.png" }) {
                    childImageSharp {
                    fluid(quality: 100, maxWidth: 4160) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    }
                }
            }
        `
    )
    
    const sourcesHeader = [
        mobileImage.childImageSharp.fluid,
        {
          ...desktopImage.childImageSharp.fluid,
          media: `(min-width: 491px)`,
        },
    ]

    const headerContent = {
        github_link: null,
        website_link: "https://colorcomb.kyleroberts.tech",
        sources: sourcesHeader,
        headline: "ColorComb",
        copy: "Create palettes and get different shades or find color palettes with ColorComb. It's a web app built with PHP and MySQL."
    }

    const TechUsed = [
        "PHP",
        "MySQL"
    ]

    const TechEntries = TechUsed.map((techName) => (
        <TechEntry key={techName} TechItem={techName} />
    ))
    
    return (
        <Layout>
            <ProjectHeader content={headerContent} />
            <TechnologySection>
                {TechEntries}
            </TechnologySection>

            <BreakdownGrid>

                <BreakdownSection title="Details">
                    <p>
                        ColorComb was my first real introduction to full-stack web development.
                        The goal of the project was to learn how to create a multi-page web application
                        that performed CRUD operations.
                    </p>

                    <p>
                        ColorComb manages the creation and authentication of accounts using PHP and stores
                        data and credentials in a MySQL instance. All account passwords are stored as 
                        BCrypt hashes and authorization is managed through server-side sessions and cookies. 
                    </p>

                    <p>
                        Features such as creating or viewing palettes, liking other user's creations are examples 
                        of CRUD operations to manipulate SQL database records.
                    </p>
                </BreakdownSection>

                <BreakdownSection title="Lessons Learned">
                    <p>
                        When starting out, it's important to understand the low-level processes that take place in web apps.
                        These foundational skills then are highly transferable to modern web server frameworks like Laravel
                        or Spring Boot. Frameworks not only make your life easier but give a standard practice to the development
                        of applications which helps other developers understand the codebase faster.
                    </p>

                    <p>
                        The ColorComb project also helped me understand that you should try to split your application into tiers.
                        Instead of writing PHP with HTML/CSS/JavaScript, you want to separate the frontend and backend. Then you 
                        can communicate actions using HTTP which helps improve app security and clean up the codebase.
                    </p>
                </BreakdownSection>

            </BreakdownGrid>

        </Layout>
    );

}

export default ProjectTurnip;